//
// Dark Sidebar(used by Dark Sidebar layout only)
//

[data-kt-app-layout="dark-sidebar"] {
    .app-sidebar {
        background-color: $app-sidebar-dark-bg-color;
        border-right: 0;

        .scroll-y,
        .hover-scroll-overlay-y {        
            @include scrollbar-color(transparent, $app-sidebar-dark-scrollbar-color-hover);
        }

        .app-sidebar-logo {
            border-bottom: 1px dashed $app-sidebar-dark-separator-color;
        }

        .btn-custom {
            @include button-custom-variant(
                $color:#B5B5C3, 
                $icon-color: #B5B5C3, 
                $border-color: null, 
                $bg-color: rgba(63, 66, 84, 0.35), 
                $color-active: #B5B5C3, 
                $icon-color-active: null, 
                $border-color-active: null, 
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );
        }

        .menu {
            > .menu-item {                    
                .menu-heading {
                    color: $app-sidebar-dark-menu-heading-color !important;
                }

                @include menu-link-default-state( 
                    $title-color: $gray-300, 
                    $icon-color:  #7F8194, 
                    $bullet-color:#7F8194,  
                    $arrow-color: var(--#{$prefix}gray-700), 
                    $bg-color: null 
                );
                
                @include menu-link-hover-state( 
                    $title-color: var(--#{$prefix}primary-inverse), 
                    $icon-color: var(--#{$prefix}primary-inverse), 
                    $bullet-color: var(--#{$prefix}primary-inverse), 
                    $arrow-color: var(--#{$prefix}primary-inverse), 
                    $bg-color: null
                );
                
                @include menu-link-here-state( 
                    $title-color: $gray-300, 
                    $icon-color: var(--#{$prefix}primary-inverse), 
                    $bullet-color: var(--#{$prefix}primary-inverse), 
                    $arrow-color: var(--#{$prefix}gray-700), 
                    $bg-color: null
                );
                
                @include menu-link-show-state( 
                    $title-color: $gray-300, 
                    $icon-color: var(--#{$prefix}primary-inverse), 
                    $bullet-color: var(--#{$prefix}primary-inverse), 
                    $arrow-color: var(--#{$prefix}gray-700), 
                    $bg-color: null
                );
                
                @include menu-link-active-state( 
                    $title-color: var(--#{$prefix}primary-inverse), 
                    $icon-color: var(--#{$prefix}primary-inverse), 
                    $bullet-color: var(--#{$prefix}primary-inverse), 
                    $arrow-color: var(--#{$prefix}primary-inverse), 
                    $bg-color: $app-sidebar-dark-menu-link-bg-color-active
                );

                .menu-sub {
                    .menu-item {
                        .menu-link {
                            .menu-title {
                                color: $app-sidebar-dark-menu-sub-link-color;
                            }

                            &.active {
                                .menu-title {
                                    color: var(--#{$prefix}white);
                                } 
                                
                                .menu-bullet {
                                    .bullet {
                                        background-color: var(--#{$prefix}white);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
}

// Desktop mode
@include media-breakpoint-up(lg) {
    [data-kt-app-layout="dark-sidebar"] {
        .app-sidebar {
            .menu-sub-lg-dropdown {
                background-color: $app-sidebar-dark-bg-color;
            }
        }
    }
}

// Dark mode
@include color-mode(dark) {
    [data-kt-app-layout="dark-sidebar"] {
        .app-sidebar {
            border-right: 1px dashed $app-sidebar-dark-separator-color;
        }
    }
}